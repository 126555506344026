import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Progress,
  Select,
  SimpleGrid,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import moment from "moment";
import {
  ReactElement,
  useEffect,
  useState,
  PropsWithChildren,
  FunctionComponent,
  ChangeEventHandler,
  Fragment,
} from "react";
import { FiSearch } from "react-icons/fi";
import {
  add_patient,
  edit_patient,
  get_file_link,
  get_file_upload_link,
  get_patient,
  get_patients_statistics,
  get_prefill,
  get_projects,
  get_sites,
  patients_export,
  prefill_from_file,
} from "../../common/Api";
import {
  AddPatientSchema,
  Patient,
  Project,
  Question,
  Site,
} from "../../common/Models";
import { useSearch } from "../../common/Pages";
import { GeneralModal } from "../users/Modal";
import { useSearchParams } from "react-router-dom";
import { LoadingSpinner, ToLocalDate } from "../../common/Components";
import { FaDownload } from "react-icons/fa";
import "./patients.css";
import axios, { AxiosResponse } from "axios";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { getPermissionList } from "../../token";
import { URLSearchParams } from "url";
import { warn } from "console";

export const TODAY = moment().format("yyyy-MM-DD");

enum ModalModes {
  ADD,
  DETAILS,
  EDIT,
  EXPORT,
}

export function PatientManagement() {
  const toast = useToast();
  const permissions = getPermissionList();

  const [searchParams, setSearchParams] = useSearchParams();
  const [, , mode, id, initialQuery] = useSearch(undefined, {
    name: "project_id",
    kind: "integer",
    value: searchParams.get("project_id") || "0",
  });

  const [query, setQuery] = useState(initialQuery);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(
    parseInt(searchParams.get("project_id") || localStorage.getItem("last_project") || "0"),
  );

  const [patient, setPatient] = useState(null as Patient | null);
  const [projects, setProjects] = useState(null as Project[] | null);
  const [sites, setSites] = useState(null as Site[] | null);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setSearchParams((params) => {
        params.set("mode", "-1");
        params.set("id", "0");
        setQuery("");
        createForm.resetForm();
        return params;
      });
      setPatient(null);
    },
  });

  const createForm = useFormik({
    initialValues: {
      id: "",
      site_id: 0,
      project_id: 0,
      sex_at_birth: 0,
      age: 0,
      birth_country: "",
      height: 0,
      weight: 0,
      bmi: 0,
      answers: {},
    } as Patient,
    validationSchema: AddPatientSchema,
    onSubmit: async (values) => {
      const body: any = { ...values };
      body.answers = {} as Record<number, string | number | Array<string>>;

      const project = projects?.filter((v) => v.id == body.project_id)[0];
      if (project == null) return;

      for (var i = 0; i < project.survey.length; i++) {
        const qname = `q${project.survey[i].id}`;
        body.answers[project.survey[i].id] = body[qname] || null;

        delete body[qname];
      }
      toast.promise(
        add_patient(body).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Created" },
          error: {
            title: "Failed to Create",
            description: "Please contact support",
          },
          loading: { title: "Creating..." },
        },
      );
    },
  });

  const editForm = useFormik({
    initialValues: {
      id: "",
      site_id: 0,
      project_id: 0,
      sex_at_birth: 0,
      age: 0,
      birth_country: "",
      height: 0,
      weight: 0,
      bmi: 0,
      answers: {},
    } as Patient,
    validationSchema: AddPatientSchema,
    onSubmit: async (values) => {
      if (patient == null) return;

      const { project_id, site_id, ...body }: any = { ...values };

      body.answers = {} as Record<number, string | number | Array<string>>;

      const project = projects?.filter((v) => v.id == project_id)[0];
      if (project == null) return;

      for (var i = 0; i < project.survey.length; i++) {
        const qname = `q${project.survey[i].id}`;
        body.answers[project.survey[i].id] = body[qname] || null;

        delete body[qname];
      }
      toast.promise(
        edit_patient(patient.id, project_id, body).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Modified" },
          error: {
            title: "Failed to Modify",
            description: "Please contact support",
          },
          loading: { title: "Modifying..." },
        },
      );
    },
  });

  const exportForm = useFormik({
    initialValues: {},
    onSubmit: async (values: any) => {
      patients_export({ project_id: project, filters: values })
        .then((res: AxiosResponse) => {
          const filename =
            "patients_export_" +
            new Date()
              .toJSON()
              .slice()
              .replaceAll("-", "_")
              .replaceAll(".", "_")
              .replaceAll(":", "_")
              .slice(0, -1) +
            ".csv";
          download(res.data, filename);
        })
        .catch((err) => {
          if (err.response.status === 404)
            toast({
              title: "There are no patients that match the criteria specified",
              status: "warning",
            });
          else
            toast({
              title: "An error happened",
              description: "Please contact support",
              status: "error",
            });
        });
    },
  });

  const fileForm = useFormik({
    initialValues: {
      file: null as File | null,
    },
    onSubmit: (values) => {
      if (!values.file || !createForm.values.project_id) {
        fileForm.setSubmitting(false);
        return;
      }

      const tid = toast({
        title: "Uploading file...",
        status: "loading",
        duration: null,
      });
      get_file_upload_link(values.file.name)
        .then((res) => {
          createForm.setFieldValue("filename", res.data.fn);
          axios
            .post(
              res.data.signed.url,
              { ...res.data.signed.fields, file: values.file },
              {
                headers: { "Content-Type": "multipart/form-data" },
              },
            )
            .then(() => {
              const filename = res.data.fn;

              prefill_from_file({
                filename: res.data.fn,
                project_id: createForm.values.project_id as number,
                site_id: createForm.values.site_id as number,
              })
                .then(async (res) => {
                  var status = -1;
                  while (true) {
                    await new Promise((r) => setTimeout(r, 2000));
                    if (status !== -1) break;

                    const d = await get_prefill(
                      createForm.values.site_id as number,
                      filename,
                    );

                    if (d.data === "Failed") {
                      status = 0;
                    } else if (d.data !== "Processing") {
                      status = 1;
                      Object.entries(d.data).forEach(([k, v]) => {
                        if (k === "sex_at_birth") {
                          createForm.setFieldValue(k, v === "male" ? 1 : 0);
                          return;
                        }
                        
                        if (k === "age" && (typeof v === "string" || v instanceof String) && parseFloat(v.toString())) {
                          createForm.setFieldValue(k, parseFloat(v.toString()));
                          return;
                        }

                        const project = projects?.filter(
                          (v) => v.id == createForm.values.project_id,
                        )[0];

                        if (project != null) {
                          const qkind = k.startsWith("q")
                            ? project.survey.filter(
                                (v) => v.id === parseInt(k.substring(1)),
                              )[0].kind
                            : 0;

                          if (qkind === 3)
                            createForm.setFieldValue(
                              k,
                              Number.isInteger(v) ||
                                parseInt(new String(v).toString()) ||
                                null,
                            );
                          else if (qkind === 4) {
                            createForm.setFieldValue(
                              k,
                              (Date.parse(new String(v).toString()) && v) ||
                                null,
                            );
                          } else {
                            createForm.setFieldValue(k, v);
                          }
                        }
                      });
                      fileForm.setSubmitting(false);
                      toast.update(tid, {
                        title: "Finished analyzing file",
                        status: "success",
                        isClosable: true,
                      });
                    }
                  }

                  if (status === 0) {
                    toast.update(tid, {
                      title: "Failed to parse answers",
                      status: "error",
                      isClosable: true,
                    });
                  } else {
                    toast.update(tid, {
                      title: "Finished analyzing file",
                      status: "success",
                      isClosable: true,
                    });
                  }
                  fileForm.setSubmitting(false);
                })
                .catch((err) => {
                  toast.update(tid, {
                    title: "Something went wrong",
                    description: err.response ? err.response.data : err.message,
                    status: "error",
                    isClosable: true,
                  });
                  fileForm.setSubmitting(false);
                });
            })
            .catch((err) => {
              toast.update(tid, {
                title: "Something went wrong",
                description: err.response.data || err.message,
                status: "error",
                isClosable: true,
              });
              fileForm.setSubmitting(false);
            });
        })
        .catch((err) => {
          toast.update(tid, {
            title: "Something went wrong",
            description: err.response.data || err.message,
            status: "error",
            isClosable: true,
          });
          fileForm.setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    switch (mode) {
      case ModalModes.ADD:
        createForm.resetForm();
        break;
      case ModalModes.EDIT:
        get_patient(query.toString(), project as number)
          .then((res) => {
            const patient = res.data as Patient;
            setPatient(patient);
            const answers: Record<string, any> = {};
            patient.answers.forEach((v) => {
              answers[`q${v.question_id}`] = v.answer_number || v.answer_text;
            });

            editForm.setValues({
              id: patient.id,
              site_id: patient.site_id,
              sex_at_birth: patient.sex_at_birth,
              age: patient.age,
              birth_country: patient.birth_country,
              height: patient.height,
              weight: patient.weight,
              bmi: patient.bmi,
              project_id: patient.project_id,
              ...answers,
            } as Patient);
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          });
        break;
      case ModalModes.DETAILS:
        get_patient(query.toString(), project as number)
          .then((res) => {
            const patient = res.data as Patient;
            setPatient(patient);
            onOpen();
          })
          .catch(() => {
            toast({ title: "No Patient Found with this ID", status: "error" });
            onClose();
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case ModalModes.EXPORT:
        break;
      default:
        setPatient(null);
        return;
    }
    onOpen();
  }, [searchParams, setSearchParams]);

  // useEffect(() => {
  //   if (mode !== -1) return;
  //   setIsLoading(true);
  //   get_patients(page as number, pageSize as number, query as string)
  //     .then((res) => {
  //       const d = res.data.patients as Array<Patient>;
  //       const t = res.data.total;
  //       setPatients(d);
  //       setTotalCount(t);
  //     })
  //     .catch(() => {
  //       toast({ title: "Failed to Get", status: "error" });
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, [page, pageSize, query, mode]);

  // if (isLoading) {
  //   return (
  //     <Container
  //       textAlign={"center"}
  //       alignSelf={"center"}
  //       py={{ base: "4", md: "8" }}
  //       px={{ base: "0", md: 8 }}
  //     >
  //       <LoadingSpinner />
  //     </Container>
  //   );
  // }

  const [stats, setStats] = useState([]);

  useEffect(() => {
    if (!project || project === 0) return;

    get_patients_statistics(project).then((res) => {
      setStats(res.data);
    });
    exportForm.setValues({
      sex_at_birth: {
        label: "Sex at Birth",
        enabled: false,
        kind: 1,
        options: [false, false],
        option_labels: ["Female", "Male"],
      },
      age: {
        label: "Age",
        enabled: false,
        kind: 3,
        min: 0,
        max: 120,
      },
      height: {
        label: "Height",
        enabled: false,
        kind: 3,
        min: 0,
        max: 200,
      },
      weight: {
        label: "Weight",
        enabled: false,
        kind: 3,
        min: 0,
        max: 500,
      },
      bmi: {
        label: "BMI",
        enabled: false,
        kind: 3,
        min: 0,
        max: 100,
      },
      ...questionsToFilters(
        projects?.filter((p) => p.id === project)[0]?.survey || [],
      ),
    });
  }, [project, searchParams]);

  useEffect(() => {
    get_projects(1, 0, "", "").then((res) => {
      const d = res.data.projects as Array<Project>;
      setProjects(d);
      if (d.length > 0) {
        createForm.setFieldValue("project_id", project == 0 && d.length > 0 ? d[0].id : 0);
      }
    });

    get_sites(1, 0, "", "").then((res) => {
      const d = res.data.sites as Array<Site>;
      setSites(d);
    });
  }, []);

  if (isLoading) {
    return (
      <Container
        textAlign={"center"}
        alignSelf={"center"}
        py={{ base: "4", md: "8" }}
        px={{ base: "0", md: 8 }}
      >
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container h="100%">
      <Stack direction="column" minH="100vh" justifyContent="flex-start" my={4}>
        <Stack direction="column" alignItems="baseline" pb={20}>
          <Stack direction="row" w="100%" justifyContent="space-between">
            <MyDropDown
              name="project_id_search"
              label="Project"
              options={projects?.map((v) => v.title) || []}
              value={
                projects?.filter((v) => project === v.id).length
                  ? projects?.filter((v) => project == v.id)[0].title || ""
                  : ""
              }
              setValue={(v: string) => {
                const val = projects?.filter((x) => x.title === v)[0].id;
                if (val == null) return;

                setProject(val);
                createForm.setFieldValue("project_id", val);
                localStorage.setItem("last_project", val.toString());
              }}
            />
            <Stack direction="row">
              {(permissions[1].verb & 1) != 0 && (
                <Button
                  onClick={() => {
                    setSearchParams((params) => {
                      params.set("id", "-1");
                      params.set("mode", ModalModes.EXPORT.toString());
                      return params;
                    });
                  }}
                >
                  Export Patient Data
                </Button>
              )}
              {(permissions[1].verb & 2) != 0 && (
                <Button
                  id="add"
                  onClick={() => {
                    if ((permissions[1].verb & 2) == 0) return;

                    setSearchParams((params) => {
                      params.set("id", "-1");
                      params.set("mode", ModalModes.ADD.toString());
                      return params;
                    });
                  }}
                >
                  Add Patient
                </Button>
              )}
            </Stack>
          </Stack>
          <Divider
            orientation="horizontal"
            mt={2}
            mb={5}
            colorScheme="blue"
            borderWidth="thin"
            rounded="lg"
          />
          {(permissions[1].verb & 1) != 0 && (
            <Stack direction="row" w="100%" justifyContent="center">
              <InputGroup maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                </InputLeftElement>
                <Input
                  placeholder="Patient ID"
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  value={query as string}
                  autoFocus
                />
              </InputGroup>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setSearchParams((params) => {
                    params.set("query", query as string);
                    params.set("mode", ModalModes.DETAILS.toString());
                    params.set("id", query as string);
                    return params;
                  });
                  setIsLoading(true);
                }}
                type="submit"
                isLoading={isLoading}
              >
                Search
              </Button>
            </Stack>
          )}
        </Stack>
        <Center>
          <Stats stats={stats} />
        </Center>
      </Stack>
      {(permissions[1].verb & 2) != 0 && mode === ModalModes.ADD && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={createForm.handleSubmit}
          isSubmitting={createForm.isSubmitting}
          body={
            <AddPatientElement
              formik={createForm}
              fileForm={fileForm}
              projects={projects}
              sites={sites}
              isEdit={false}
            />
          }
          title={"Add Patient"}
          buttonText="Save"
        />
      )}
      {(permissions[1].verb & 2) != 0 && mode === ModalModes.EDIT && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={editForm.handleSubmit}
          isSubmitting={editForm.isSubmitting}
          body={
            <AddPatientElement
              formik={editForm}
              fileForm={fileForm}
              projects={projects}
              sites={sites}
              isEdit={true}
            />
          }
          title={"Edit Patient"}
          buttonText="Save"
        />
      )}
      {mode === ModalModes.DETAILS && (permissions[1].verb & 1) != 0 && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          body={
            <DetailsElement
              patient={patient}
              allowEdit={(permissions[1].verb & 2) != 0}
              setSearchParams={setSearchParams}
            />
          }
          title={"Patient Details"}
        />
      )}
      {mode === ModalModes.EXPORT && (permissions[1].verb & 1) != 0 && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={exportForm.handleSubmit}
          isSubmitting={exportForm.isSubmitting}
          body={<ExportPatientsElement formik={exportForm} />}
          title={"Export Data"}
          buttonText="Export"
        />
      )}
    </Container>
  );
}

const FilterWrapper: FunctionComponent<
  PropsWithChildren & {
    label: string;
    enabled: boolean;
    setEnabled: ChangeEventHandler<HTMLInputElement>;
  }
> = ({ children, label, enabled, setEnabled }) => (
  <div className="w-full">
    <div className="flex flex-row w-full">
      <input type="checkbox" checked={enabled} onChange={setEnabled} />
      <h3 className="ml-1 text-gray-900 text-sm">{label}</h3>
    </div>
    {children}
  </div>
);

function ExportPatientsElement(props: { formik: any }): ReactElement {
  const formik = props.formik;

  console.log(formik.values);

  const inputs = Object.entries(formik.values).map(([k, v]: any[]) => {
    switch (v.kind) {
      case 1:
        return (
          <FilterWrapper
            key={k}
            label={v.label}
            enabled={v.enabled}
            setEnabled={(e) => {
              const newVal = v;
              newVal.enabled = e.target.checked;
              formik.setFieldValue(k, newVal);
            }}
          >
            <div className={"flex flex-row" + v.enabled ? "" : " disabled"}>
              <MyCheckboxGroup
                name={k}
                formik={formik}
                options={v.option_labels}
                label={""}
                lowercaseValue={false}
                onChange={(e) => {
                  const newVal = v;
                  newVal.options[v.option_labels.indexOf(e.target.value)] =
                    e.target.checked;

                  console.log(v);
                  console.log(newVal);
                  console.log(e);

                  formik.setFieldValue(k, newVal);
                }}
                disabled={!v.enabled}
                customChecked={true}
              />
            </div>
          </FilterWrapper>
        );
      // case 2:
      //   return (
      //     <FilterWrapper
      //       key={k}
      //       label={v.label}
      //       enabled={v.enabled}
      //       setEnabled={(e) => {
      //         const newVal = v;
      //         newVal.enabled = e.target.checked;
      //         formik.setFieldValue(k, newVal);
      //       }}
      //     >
      //       <div className="flex flex-col">
      //         <MyRadioGroup
      //           name={`${k}_and`}
      //           formik={formik}
      //           option_labels={["all of", "at least one of"]}
      //           options={[0, 1]}
      //           label={""}
      //           onChange={(e) => {
      //             const newVal = v;
      //             newVal.and = parseInt(e.target.value) === 0;
      //             formik.setFieldValue(k, newVal);
      //           }}
      //           disabled={!v.enabled}
      //         />
      //         <MyCheckboxGroup
      //           name={k}
      //           formik={formik}
      //           options={v.option_labels}
      //           label={""}
      //           lowercaseValue={false}
      //           customChecked={true}
      //           onChange={(e) => {
      //             const newVal = v;
      //             newVal.options[v.option_labels.indexOf(e.target.value)] =
      //               e.target.checked;
      //             formik.setFieldValue(k, newVal);
      //           }}
      //           disabled={!v.enabled}
      //         />
      //       </div>
      //     </FilterWrapper>
      //   );
      case 3:
        return (
          <FilterWrapper
            key={k}
            label={v.label}
            enabled={v.enabled}
            setEnabled={(e) => {
              const newVal = v;
              newVal.enabled = e.target.checked;
              formik.setFieldValue(k, newVal);
            }}
          >
            <div className="flex flex-row">
              <MyInput
                name={k}
                onChange={(e) => {
                  const newVal = v;
                  newVal.min = e.target.valueAsNumber;
                  formik.setFieldValue(k, newVal);
                }}
                formik={formik}
                value={v.min}
                label="Min"
                type="number"
                disabled={!v.enabled}
              />
              <MyInput
                name={k}
                onChange={(e) => {
                  const newVal = v;
                  newVal.max = e.target.valueAsNumber;
                  formik.setFieldValue(k, newVal);
                }}
                value={v.max}
                formik={formik}
                label="Max"
                type="number"
                disabled={!v.enabled}
              />
            </div>
          </FilterWrapper>
        );
      case 4:
        return (
          <FilterWrapper
            key={k}
            label={v.label}
            enabled={v.enabled}
            setEnabled={(e) => {
              const newVal = v;
              newVal.enabled = e.target.checked;
              formik.setFieldValue(k, newVal);
            }}
          >
            <div className="flex flex-row">
              <MyInput
                name={k}
                formik={formik}
                label="Min"
                type="date"
                disabled={!v.enabled}
              />
              <MyInput
                name={k}
                formik={formik}
                label="Max"
                type="date"
                disabled={!v.enabled}
              />
            </div>
          </FilterWrapper>
        );

      // case 5:
      //   <FilterWrapper
      //     key={k}
      //     label={v.label}
      //     enabled={v.enabled}
      //     setEnabled={(e) => {
      //       const newVal = v;
      //       newVal.enabled = e.target.checked;
      //       formik.setFieldValue(k, newVal);
      //     }}
      //   >
      //     <MyInput
      //       name={k}
      //       formik={formik}
      //       label="contains"
      //       type="text"
      //       disabled={!v.enabled}
      //     />
      //   </FilterWrapper>;
    }
  });

  return (
    <SimpleGrid columns={1} spacingY={5} key="filters">
      {inputs}
    </SimpleGrid>
  );
}

function AddPatientElement(props: {
  formik: any;
  fileForm: any;
  projects: Project[] | null;
  sites: Site[] | null;
  isEdit: boolean;
}): ReactElement {
  const formik = props.formik;
  const fileForm = props.fileForm;
  const projects = props.projects;
  const sites = props.sites;

  if (projects == null || sites == null) {
    return (
      <Container
        textAlign={"center"}
        alignSelf={"center"}
        py={{ base: "4", md: "8" }}
        px={{ base: "0", md: 8 }}
      >
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-evenly"
      >
        <Box w="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            {!props.isEdit && (
              <div className="mb-3 text-center">
                <h3 className="block mb-2 text-base font-semibold text-gray-900">
                  Pre-fill from a PDF
                </h3>
                <div className="flex flex-row flex-wrap justify-center">
                  <input
                    className="block mr-2 text-sm text-gray-500 file:me-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-sky-600 file:text-white hover:file:bg-sky-700 file:disabled:opacity-50 file:disabled:pointer-events-none"
                    id="file"
                    name="file"
                    type="file"
                    onBlur={fileForm.handleBlur}
                    onChange={(e) => {
                      e.currentTarget &&
                        e.currentTarget.files &&
                        fileForm.setFieldValue(
                          "file",
                          e.currentTarget.files[0],
                        );
                    }}
                  />
                  <Button
                    onClick={fileForm.handleSubmit}
                    isLoading={fileForm.isSubmitting}
                    isDisabled={
                      !fileForm.values.file ||
                      fileForm.values.file === null ||
                      !formik.values.project_id ||
                      formik.values.project_id === 0 ||
                      !formik.values.site_id ||
                      formik.values.site_id === 0
                    }
                  >
                    Pre-fill
                  </Button>
                </div>
              </div>
            )}
            <Divider />
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <MyInput
                name="id"
                label="Patient ID"
                type="text"
                formik={formik}
                disabled={props.isEdit}
              />
              <MyRadioGroup
                name="sex_at_birth"
                label="Sex at Birth"
                option_labels={["Female", "Male"]}
                options={[0, 1]}
                formik={formik}
                onChange={(e) => {
                  formik.setFieldValue(
                    "sex_at_birth",
                    parseInt(e.target.value),
                  );
                }}
              />
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <MyInput
                name="age"
                label="Age (Years)"
                type="number"
                formik={formik}
              />
              <MyInput
                name="height"
                label="Height (Meters)"
                type="number"
                formik={formik}
              />
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <MyInput
                name="weight"
                label="Weight (Kilograms)"
                type="number"
                formik={formik}
              />
              <MyInput name="bmi" label="BMI" type="number" formik={formik} />
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl id="project_id" isDisabled={props.isEdit}>
                <label
                  htmlFor={`number-project_id`}
                  className={"text-sm text-gray-900"}
                >
                  Project
                </label>
                <Select
                  placeholder="Select option"
                  id="project_id"
                  name="project_id"
                  onChange={(v) => {
                    if (!v.target.value) {
                      formik.setFieldValue("project_id", 0);
                      formik.setFieldValue("site_id", 0);
                      return;
                    }
                    formik.setFieldValue("site_id", 0);
                    formik.setFieldValue(
                      "project_id",
                      projects.filter((p) => p.title === v.target.value)[0].id,
                    );
                  }}
                  onBlur={formik.handleBlur}
                  value={
                    !formik.values.project_id || formik.values.project_id === 0
                      ? ""
                      : projects.filter(
                          (v) => v.id == formik.values.project_id,
                        )[0].title
                  }
                >
                  {projects.map((p) => (
                    <option key={`option-${p.id}`} value={p.title}>
                      {p.title}
                    </option>
                  ))}
                </Select>
                {formik.errors.project_id && formik.touched.project_id && (
                  <p className="text-red-500 text-xs italic">
                    {formik.errors.project_id}
                  </p>
                )}
              </FormControl>
              <FormControl
                id="site_id"
                isDisabled={
                  !formik.values.project_id ||
                  formik.values.project_id === 0 ||
                  props.isEdit
                }
              >
                <FormLabel>Site</FormLabel>

                <Select
                  placeholder="Select option"
                  id="site_id"
                  name="site_id"
                  onChange={(v) => {
                    if (!v.target.value) {
                      formik.setFieldValue("site_id", 0);
                      return;
                    }
                    formik.setFieldValue(
                      "site_id",
                      sites.filter((s) => s.name === v.target.value)[0].id,
                    );
                  }}
                  onBlur={formik.handleBlur}
                  value={
                    !formik.values.site_id || formik.values.site_id === 0
                      ? ""
                      : sites.filter(
                          (v) =>
                            v.id === formik.values.site_id &&
                            v.project_id === formik.values.project_id,
                        )[0].name
                  }
                >
                  {sites
                    .filter((v) => v.project_id === formik.values.project_id)
                    .map((s) => (
                      <option key={`option-${s.id}`} value={s.name}>
                        {s.name}
                      </option>
                    ))}
                </Select>
                {formik.errors.site_id &&
                  formik.values.project_id !== 0 &&
                  formik.touched.site_id && (
                    <p className="text-red-500 text-xs italic">
                      {formik.errors.site_id}
                    </p>
                  )}
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <MyInput
                name="birth_country"
                label="Country of Birth"
                type="text"
                formik={formik}
                // element={Select}
                // placeholder="Select a Country"
                // options={countryList}
              />
            </Stack>
            <div id="accordion-survey" data-accordion="collapse">
              <h2 id="accordion-survey-heading-1">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-gray-200 rounded-t-xl focus:ring-4 focus:ring-blue-200 hover:bg-blue-100 gap-3"
                  data-accordion-target="#accordion-survey-body-1"
                  aria-expanded="true"
                  aria-controls="accordion-survey-body-1"
                  onClick={() => {
                    const a = window.document.querySelector(
                      "#accordion-survey-body-1",
                    );
                    if (!a) return;
                    a.className = a.className === "hidden" ? "" : "hidden";
                  }}
                >
                  <span>Survey</span>
                  <svg
                    data-accordion-icon
                    className="w-3 h-3 rotate-180 shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-survey-body-1"
                className="hidden"
                aria-labelledby="accordion-survey-heading-1"
              >
                <div className="p-5 border border-gray-200">
                  {!formik.values.project_id ||
                  formik.values.project_id == 0 ? (
                    <div>Please choose a project first</div>
                  ) : (
                    projects
                      .filter((v) => {
                        return v.id === formik.values.project_id;
                      })[0]
                      .survey.map((v) => {
                        if (v.kind === 1)
                          return (
                            <MyRadioGroup
                              key={`q${v.id}`}
                              name={`q${v.id}`}
                              label={v.question}
                              formik={formik}
                              option_labels={
                                v.options ? v.options.split(",") : []
                              }
                              options={v.options ? v.options.split(",") : []}
                              disabled={fileForm.isSubmitting}
                            />
                          );
                        if (v.kind === 2)
                          return (
                            <MyCheckboxGroup
                              key={`q${v.id}`}
                              name={`q${v.id}`}
                              label={v.question}
                              options={v.options ? v.options.split(",") : []}
                              formik={formik}
                              disabled={fileForm.isSubmitting}
                            />
                          );
                        if (v.kind === 3)
                          return (
                            <MyInput
                              key={`q${v.id}`}
                              name={`q${v.id}`}
                              label={v.question}
                              formik={formik}
                              type="number"
                              disabled={fileForm.isSubmitting}
                            />
                          );
                        if (v.kind === 4)
                          return (
                            <MyInput
                              key={`q${v.id}`}
                              name={`q${v.id}`}
                              label={v.question}
                              formik={formik}
                              type="date"
                              disabled={fileForm.isSubmitting}
                            />
                          );
                        if (v.kind === 5)
                          return (
                            <MyInput
                              key={`q${v.id}`}
                              name={`q${v.id}`}
                              label={v.question}
                              formik={formik}
                              type="text"
                              disabled={fileForm.isSubmitting}
                            />
                          );
                      })
                  )}
                </div>
              </div>
            </div>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function MyRadioGroup(props: {
  name: string;
  label: string;
  formik: any;
  options: any[];
  option_labels: string[];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}) {
  const name = props.name;
  const formik = props.formik;
  const options = props.options;

  return (
    <div className="w-full">
      <h3 className="text-sm text-gray-900">{props.label}</h3>
      <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex">
        {options.map((v, idx) => (
          <li
            key={v}
            className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r"
          >
            <div className="flex items-center ps-3">
              <input
                id={`horizontal-list-radio-${name}-${idx}`}
                type="radio"
                value={isString(v) ? v.toLocaleLowerCase() : v}
                name={name}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                onBlur={formik.handleBlur}
                onChange={props.onChange || formik.handleChange}
                disabled={props.disabled}
                checked={
                  (isString(v) ? v.toLocaleLowerCase() : v) ===
                  formik.values[name]
                }
              />
              <label
                htmlFor={`horizontal-list-radio-${name}-${idx}`}
                className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
              >
                {props.option_labels[idx]}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

function MyCheckboxGroup(props: {
  name: string;
  label: string;
  formik: any;
  options: string[];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  lowercaseValue?: boolean;
  customChecked?: boolean;
}) {
  const name = props.name;
  const formik = props.formik;
  const options = props.options;
  var lowercaseValue = props.lowercaseValue;
  if (lowercaseValue !== false) lowercaseValue = true;

  return (
    <div className="w-full">
      <h3 className="text-gray-900 text-sm">{props.label}</h3>
      <ul
        className={
          "items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex" +
          (props.disabled ? " disabled" : "")
        }
      >
        {options.map((v, idx) => (
          <li
            key={v}
            className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r"
          >
            <div className="flex items-center ps-3">
              <input
                id={`checkbox-${name}-${idx}`}
                type="checkbox"
                name={name}
                value={lowercaseValue ? v.toLocaleLowerCase() : v}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                onBlur={formik.handleBlur}
                onChange={props.onChange || formik.handleChange}
                disabled={props.disabled}
                checked={
                  props.customChecked
                    ? formik.values[name].options[idx]
                    : (formik.values[name] &&
                        formik.values[name].includes(
                          lowercaseValue ? v.toLocaleLowerCase() : v,
                        )) ||
                      false
                }
              />
              <label
                htmlFor={`checkbox-${name}-${idx}`}
                className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
              >
                {v}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

function MyInput(props: {
  name: string;
  label: string;
  formik: any;
  type: React.HTMLInputTypeAttribute;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  value?: any;
}) {
  const name = props.name;
  const formik = props.formik;
  return (
    <div className="w-full mr-3">
      <label
        htmlFor={`number-${name}`}
        className={
          "text-sm text-gray-900" + (props.disabled ? " disabled" : "")
        }
      >
        {props.label}
      </label>
      <input
        type={props.type}
        id={`number-${name}`}
        name={name}
        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        onBlur={formik.handleBlur}
        onChange={props.onChange || formik.handleChange}
        step={props.type === "number" ? "0.01" : ""}
        disabled={props.disabled === true}
        value={props.value || formik.values[name]}
      />
      {formik.errors[name] && formik.touched[name] && (
        <p className="text-red-500 text-xs italic">{formik.errors[name]}</p>
      )}
    </div>
  );
}

const download = function (data: any, filename: string) {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  a.click();
};

const Stats = (props: { stats: any[] }) => {
  const stats = props.stats;
  return (
    <Flex alignSelf="start">
      <Wrap>
        {stats.map((stat, id) => (
          <WrapItem key={id}>
            <Stat stats={stat} />
          </WrapItem>
        ))}
      </Wrap>
    </Flex>
  );
};

const Stat = (props: {
  stats: {
    label: string;
    value: number;
  }[];
}) => {
  return (
    <Box bg="bg.surface" boxShadow="sm" m={1}>
      <Box px={{ base: "4", md: "6" }} py={{ base: "5", md: "6" }}>
        <SimpleGrid columns={props.stats.length}>
          {props.stats.map((v) => (
            <Text key={v.label} textStyle="sm" color="fg.muted" mx={1}>
              {v.label}
            </Text>
          ))}
          {props.stats.map((v) => (
            <Heading key={v.label} size={{ base: "sm", md: "md" }} mx={1}>
              {v.value}
            </Heading>
          ))}
        </SimpleGrid>
      </Box>
      <Progress size="xs" borderRadius="none" bg="lightgreen" />
    </Box>
  );
};

function DetailsElement(props: {
  patient: Patient | null;
  allowEdit: boolean;
  setSearchParams: any;
}): ReactElement {
  if (props.patient === null) return <LoadingSpinner />;
  const answers = props.patient.answers;

  return (
    <>
      <Tabs variant="soft-rounded" colorScheme="blue">
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Files</Tab>
          <Tab>Information</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {props.allowEdit && (
              <Button
                mb={2}
                onClick={() => {
                  const patient = props.patient;
                  if (patient == null) return;

                  props.setSearchParams((params: URLSearchParams) => {
                    params.set("id", patient.id);
                    params.set(
                      "project_id",
                      patient.project_id?.toString() || "",
                    );
                    params.set("mode", ModalModes.EDIT.toString());
                    return params;
                  });
                }}
              >
                Edit
              </Button>
            )}
            <Center>
              <Table size="sm" variant="striped">
                <Tbody>
                  <Tr>
                    <Td fontWeight="bold">ID</Td>
                  </Tr>
                  <Tr>
                    <Td>{props.patient.id}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Project</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      {props.patient.project ? (
                        <Link
                          href={`/projects?id=${props.patient.project.id}&mode=2`}
                          isExternal
                        >
                          {props.patient.project.title}
                          <ExternalLinkIcon mx="2px" />
                        </Link>
                      ) : (
                        "None"
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Sex at Birth</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      {props.patient.sex_at_birth === 1 ? "Male" : "Female"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Age</Td>
                  </Tr>
                  <Tr>
                    <Td>{props.patient.age}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Country of Birth</Td>
                  </Tr>
                  <Tr>
                    <Td>{props.patient.birth_country}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Height</Td>
                  </Tr>
                  <Tr>
                    <Td>{props.patient.height}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Weight</Td>
                  </Tr>
                  <Tr>
                    <Td>{props.patient.weight}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">BMI</Td>
                  </Tr>
                  <Tr>
                    <Td>{props.patient.bmi}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Data Entered At</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      {props.patient.created_at
                        .toString()
                        .replace(/\..*$/g, "")}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Center>
          </TabPanel>
          <TabPanel>
            <Center>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th fontWeight="bold">Date</Th>
                    <Th fontWeight="bold">Filename</Th>
                    <Th fontWeight="bold">Uploaded By</Th>
                    <Th fontWeight="bold">Type</Th>
                    <Th fontWeight="bold">Notes</Th>
                    <Th fontWeight="bold"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {props.patient.files.map((c) => (
                    <Tr key={c.id}>
                      <Td>{`${ToLocalDate(c.created_at)}`} </Td>
                      <Td> {`${c.name}`}</Td>
                      <Td> {`${c.uploaded_by}`}</Td>
                      <Td></Td>
                      <Td>{c.notes}</Td>
                      <Td>
                        <FaDownload
                          className="download_button"
                          onClick={() => {
                            get_file_link(c.id).then((res: any) => {
                              window.open(res.data, "_newtab");
                            });
                          }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Center>
          </TabPanel>
          <TabPanel>
            <Center>
              <Table size="sm">
                <Tbody>
                  {answers.map((c, idx) => (
                    <Fragment key={idx}>
                      <Tr>
                        <Td fontWeight="bold">{c.question}</Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {c.answer_number || c.answer_text || "Not Answered"}
                        </Td>
                      </Tr>
                    </Fragment>
                  ))}
                </Tbody>
              </Table>
            </Center>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

function MyDropDown(props: {
  name: string;
  label: string;
  options: string[];
  value: string;
  setValue: Function;
}) {
  const [isOpen, setOpen] = useState(false);
  const close = () => setOpen(false);
  return (
    <div className="w-1/3">
      <div className="relative mt-2">
        <button
          type="button"
          className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
          onClick={() => setOpen((v) => !v)}
        >
          <span className="flex items-center">
            <span className="ml-3 block truncate">{props.value}</span>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <svg
              className="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>
        {isOpen && (
          <ul
            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            tabIndex={-1}
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3"
          >
            {props.options.map((v, idx) => (
              <li
                key={v}
                className="text-gray-900 hover:bg-indigo-600 hover:text-white relative cursor-default select-none py-2 pl-3 pr-9 group"
                id={`listbox-option-${idx}`}
                role="option"
                onClick={() => {
                  props.setValue(v);
                  close();
                }}
              >
                <div className="flex items-center font-normal group-hover:font-semibold">
                  <span className="ml-3 block truncate">{v}</span>
                </div>
                {props.value === v && (
                  <span className="text-indigo-600 group-hover:text-white font-bold absolute inset-y-0 right-0 flex items-center pr-4">
                    X
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

function questionsToFilters(questions: Question[]): any {
  const res: any = {};
  questions.forEach((v) => {
    res[v.id] = { enabled: false, kind: v.kind, label: v.question };
    switch (v.kind) {
      case 1:
        if (!v.options) return;

        res[v.id]["option_labels"] = v.options.split(",");
        res[v.id]["options"] = new Array<boolean>(
          res[v.id]["option_labels"].length,
        ).fill(false);
        break;
      case 2:
        if (!v.options) return;

        res[v.id]["and"] = false;
        res[v.id]["option_labels"] = v.options.split(",");
        res[v.id]["options"] = new Array<boolean>(
          res[v.id]["option_labels"].length,
        ).fill(false);
        break;
      case 3:
        res[v.id]["min"] = 0;
        res[v.id]["max"] = 100;
        break;
      case 4:
        res[v.id]["min"] = TODAY;
        res[v.id]["max"] = TODAY;
        break;
      case 5:
        res[v.id]["contains"] = "";
        break;
    }
  });

  return res;
}

function isString(x: any) {
  return Object.prototype.toString.call(x) === "[object String]";
}
