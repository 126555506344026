import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Progress,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Textarea,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useFormik } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import {
  add_file,
  edit_file,
  get_file,
  get_files,
  get_file_link,
  get_file_upload_link,
  get_patients,
  get_projects,
  get_sites,
} from "../../common/Api";
import { LoadingSpinner } from "../../common/Components";
import {
  AddFileSchema,
  File,
  Patient,
  Project,
  Site,
} from "../../common/Models";
import { Pagination, useSearch } from "../../common/Pages";
import { MyTable } from "../users/MemberTable";
import { GeneralModal } from "../users/Modal";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { useSearchParams } from "react-router-dom";
import { getPermissionList } from "../../token";
import { BiDetail } from "react-icons/bi";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { FiEdit2 } from "react-icons/fi";

enum ModalModes {
  ADD,
  DETAILS,
  EDIT,
}

function addFile(values: { name: string; file: File; notes: string }) {
  return get_file_upload_link(values.file.name);
}

export function FileManagement() {
  const toast = useToast();

  const permissions = getPermissionList();
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, pageSize, mode, id, query, sort] = useSearch();

  const [totalCount, setTotalCount] = useState(1);
  const [file, setFile] = useState(null as File | null);
  const [files, setFiles] = useState(new Array<File>());
  const [progress, setProgress] = useState(0);
  const [projects, setProjects] = useState(null as Array<Project> | null);
  const [patients, setPatients] = useState(null as Array<Patient> | null);
  const [allSites, setAllSites] = useState(null as Array<Site> | null);
  const [sites, setSites] = useState([] as Array<Site>);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      setProgress(0);
      fileForm.resetForm();
    },
    onClose: () => {
      setSearchParams((params) => {
        params.set("mode", "-1");
        params.set("id", "0");
        fileForm.resetForm();
        return params;
      });
      setFile(null);
      fileForm.resetForm();
    },
  });

  const editForm = useFormik({
    initialValues: {
      project_id: 0,
      patient_id: "",
      site_id: 0,
      notes: "",
    },
    onSubmit: (values) => {
      if (!file) return;
      toast.promise(
        edit_file(file.id, values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Modified" },
          error: {
            title: "Failed to Modify",
            description: "Please contact support",
          },
          loading: { title: "Modifying..." },
        },
      );
    },
  });
  const fileForm = useFormik({
    initialValues: {
      name: "",
      file: null as any,
      project_id: 0,
      patient_id: "",
      site_id: 0,
      notes: "",
    },
    validationSchema: AddFileSchema,
    onSubmit: (values) => {
      addFile(values)
        .then((res) => {
          axios
            .post(
              res.data.signed.url,
              { ...res.data.signed.fields, file: values.file },
              {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) =>
                  setProgress((progressEvent.loaded / values.file.size) * 100),
              },
            )
            .then(() => {
              const file_body = {
                name: values.name,
                notes: values.notes,
                project_id: values.project_id,
                patient_id: values.patient_id,
                site_id: values.site_id,
                filename: res.data.fn,
              };
              add_file(file_body)
                .then(() => {
                  setSearchParams((params) => {
                    params.set("mode", "-1");
                    return params;
                  });
                })
                .catch((err) => {
                  toast({
                    title: "Something went wrong",
                    description: err.response.data || err.message,
                    status: "error",
                  });
                });
            })
            .catch((err) => {
              toast({
                title: "Something went wrong",
                description: err.response.data || err.message,
                status: "error",
              });
            });
        })
        .catch((err) => {
          toast({
            title: "Something went wrong",
            description: err.response.data || err.message,
            status: "error",
          });
        });
    },
  });

  useEffect(() => {
    switch (mode) {
      case ModalModes.DETAILS:
        get_file(id as number)
          .then((res) => {
            setFile(res.data as File);
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case ModalModes.EDIT:
        get_file(id as number)
          .then((res) => {
            const file = res.data as File;
            setFile(file);
            editForm.setValues({
              project_id: file.project_id,
              site_id: file.site_id,
              patient_id: file.patient_id,
              notes: file.notes,
            });
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          });
        break;
      case -1:
        return;
    }
    onOpen();
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    setIsLoading(true);
    get_files(
      page as number,
      pageSize as number,
      query as string,
      sort as string,
    )
      .then((res) => {
        const d = res.data.files as Array<File>;
        const t = res.data.total;
        setFiles(d);
        setTotalCount(t);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, pageSize, query, sort, mode]);

  useEffect(() => {
    get_projects(1, 0, "", "").then((res) => {
      const d = res.data.projects as Array<Project>;
      setProjects(d);
      if (d.length > 0) {
        fileForm.setFieldValue("project_id", d[0].id);
      }
    });
    get_sites(1, 0, "", "").then((res) => {
      const d = res.data.sites as Array<Site>;
      setAllSites(d);
    });
  }, []);

  useEffect(() => {
    get_patients(fileForm.values.project_id).then((res) => {
      const d = res.data.patients as Array<Patient>;
      setPatients(d);
    });
  }, [fileForm.values.project_id]);

  useEffect(() => {
    get_patients(editForm.values.project_id).then((res) => {
      const d = res.data.patients as Array<Patient>;
      setPatients(d);
    });
  }, [editForm.values.project_id]);

  useEffect(() => {
    if (allSites == null) return;
    const s = allSites.filter(
      (v) => v.project_id === fileForm.values.project_id,
    );
    setSites(s);
  }, [fileForm.values.project_id]);

  useEffect(() => {
    if (allSites == null) return;
    const s = allSites.filter(
      (v) => v.project_id === editForm.values.project_id,
    );
    setSites(s);
  }, [editForm.values.project_id]);

  if (isLoading) {
    return (
      <Container
        textAlign={"center"}
        alignSelf={"center"}
        py={{ base: "4", md: "8" }}
        px={{ base: "0", md: 8 }}
      >
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 8 }}>
      <Box borderRadius={{ base: "none", md: "lg" }}>
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack direction={{ base: "column", md: "row" }} justify="end">
              {/*
              <InputGroup maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  value={query}
                />
              </InputGroup>
              */}
              {(permissions[2].verb & 2) != 0 && (
                <Button
                  alignSelf="end"
                  onClick={() => {
                    setSearchParams((params) => {
                      params.set("id", "-1");
                      params.set("mode", ModalModes.ADD.toString());
                      return params;
                    });
                  }}
                >
                  Upload File
                </Button>
              )}
            </Stack>
          </Box>
          {(permissions[2].verb & 1) != 0 && (
            <Box overflowX="auto">
              <MyTable<File>
                sortColumn={sort as string}
                setSearchParams={setSearchParams}
                columns={[
                  { name: "Filename", width: "20%", sort_id: "name" },
                  { name: "Uploaded By", width: "20%", sort_id: "uploaded_by" },
                  { name: "Uploaded At", width: "15%", sort_id: "created_at" },
                  { name: "Size", width: "15%", sort_id: "size" },
                  { name: "Notes", width: "25%" },
                  { name: "Actions", width: "20%" },
                ]}
                data={files}
                bindings={[
                  (r: File) => r.name,
                  (r) => r.uploaded_by,
                  (r) => r.created_at.split(".")[0],
                  (r) => get_size_string(r.size),
                  (r) => r.notes,
                ]}
                rk={(r: File) => `${r.id}`}
                rowAction={(p) => {
                  setSearchParams((params) => {
                    params.set("id", p.id.toString());
                    params.set("mode", ModalModes.DETAILS.toString());
                    return params;
                  });
                }}
                functions={[
                  {
                    title: "Download",
                    icon: <FaDownload />,
                    fn: (p) => {
                      get_file_link(p.id).then((res) => {
                        window.open(res.data, "_newtab");
                      });
                    },
                  },
                  {
                    title: "Show Details",
                    icon: <BiDetail />,
                    fn: (p) => {
                      setSearchParams((params) => {
                        params.set("id", p.id.toString());
                        params.set("mode", ModalModes.DETAILS.toString());
                        return params;
                      });
                    },
                  },
                  {
                    title: "Edit",
                    icon: <FiEdit2 />,
                    condition: () => (permissions[2].verb & 2) != 0,
                    fn: (p) => {
                      setSearchParams((params) => {
                        params.set("id", p.id.toString());
                        params.set("mode", ModalModes.EDIT.toString());
                        return params;
                      });
                    },
                  },
                ]}
              />
            </Box>
          )}
          {(permissions[2].verb & 1) != 0 && (
            <Box bg="bg.surface">
              <Container>
                <Pagination
                  count={totalCount}
                  pageSize={pageSize as number}
                  siblingCount={2}
                  page={page as number}
                  onChange={(e: any) => {
                    setSearchParams((params) => {
                      params.set("page", e.page);
                      return params;
                    });
                  }}
                />
              </Container>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 my-3 mx-auto"
                onChange={(e) => {
                  setSearchParams((params) => {
                    params.set("pageSize", e.target.value);
                    return params;
                  });
                }}
                value={pageSize}
              >
                <option value="10">10 Results per Page</option>
                <option value="20">20 Results per Page</option>
                <option value="30">30 Results per Page</option>
                <option value="50">50 Results per Page</option>
                <option value="100">100 Results per Page</option>
              </select>
            </Box>
          )}
        </Stack>
      </Box>
      {(permissions[2].verb & 2) != 0 && mode === ModalModes.ADD && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={fileForm.handleSubmit}
          body={
            <AddFileElement
              formik={fileForm}
              progress={progress}
              projects={projects}
              patients={patients}
              sites={sites}
            />
          }
          title={"Upload File"}
          buttonText="Save"
        />
      )}
      {(permissions[2].verb & 2) != 0 && mode === ModalModes.EDIT && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={editForm.handleSubmit}
          body={
            <AddFileElement
              formik={editForm}
              disabled={[
                "name",
                "file",
                "progress",
              ]}
              progress={0}
              projects={projects}
              patients={patients}
              sites={sites}
            />
          }
          title={"Edit File"}
          buttonText="Save"
        />
      )}
      {(permissions[2].verb & 1) != 0 && mode === ModalModes.DETAILS && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          body={<ShowDetailsElement file={file} />}
          title={"File Details"}
          minW="unset"
        />
      )}
    </Container>
  );
}

function AddFileElement(props: {
  formik: any;
  progress: number;
  projects: Array<Project> | null;
  patients: Array<Patient> | null;
  sites: Array<Site>;
  disabled?: Array<string>;
}): ReactElement {
  const formik = props.formik;
  const progress = props.progress;
  const projects = props.projects;
  const patients = props.patients;
  const sites = props.sites;
  const disabled = props.disabled || [];

  if (projects === null)
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );

  console.log(sites);
  console.log(formik.values);
  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box m="0 auto" w="100%" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            {disabled.includes("name") || (
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl
                  id="name"
                  isInvalid={formik.errors.name && formik.touched.name}
                >
                  <FormLabel>Filename</FormLabel>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                </FormControl>
              </Stack>
            )}
            {disabled.includes("project_id") || (
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl
                  id="project_id"
                  isInvalid={
                    formik.errors.project_id && formik.touched.project_id
                  }
                >
                  <FormLabel>Project</FormLabel>
                  <Select
                    placeholder="Select option"
                    id="project_id"
                    name="project_id"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "project_id",
                        parseInt(e.target.value),
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.project_id}
                  >
                    {projects.map((p) => (
                      <option value={p.id} key={p.id}>
                        {p.title}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {formik.errors.project_id}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            )}
            {disabled.includes("patient_id") || (
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl id="patient_id">
                  <FormLabel>Patient ID</FormLabel>
                  <AutoComplete
                    id="patient_id"
                    openOnFocus
                    onChange={(v) => formik.setFieldValue("patient_id", v)}
                    onBlur={formik.handleBlur}
                    value={formik.values.patient_id}
                    isLoading={patients === null}
                  >
                    <AutoCompleteInput name="patient_id" />
                    <AutoCompleteList>
                      {patients !== null &&
                        patients.map((p) => (
                          <AutoCompleteItem
                            key={`option-${p.id}`}
                            value={p.id}
                            textTransform="capitalize"
                          >
                            {p.id}
                          </AutoCompleteItem>
                        ))}
                    </AutoCompleteList>
                  </AutoComplete>
                </FormControl>
              </Stack>
            )}
            {disabled.includes("site_id") || (
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl id="site_id">
                  <FormLabel>Site</FormLabel>
                  <Select
                    placeholder="Select option"
                    id="site_id"
                    name="site_id"
                    onChange={(e) => {
                      formik.setFieldValue("site_id", parseInt(e.target.value));
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.site_id}
                  >
                    {sites.map((p) => (
                      <option value={p.id} key={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.site_id}</FormErrorMessage>
                </FormControl>
              </Stack>
            )}
            {disabled.includes("file") || (
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl
                  id="file"
                  isInvalid={formik.errors.file && formik.touched.file}
                >
                  <Input
                    id="file"
                    name="file"
                    type="file"
                    onChange={(e) => {
                      e.currentTarget &&
                        e.currentTarget.files &&
                        formik.setFieldValue("file", e.currentTarget.files[0]);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <FormErrorMessage>{formik.errors.file}</FormErrorMessage>
                </FormControl>
              </Stack>
            )}
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
            {disabled.includes("progress") || (
              <Progress hasStripe value={progress} />
            )}
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function ShowDetailsElement(props: { file: File | null }): ReactElement {
  if (props.file === null) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box bg="bg.surface" borderRadius="lg">
          <Center>
            <Table size="sm" variant="striped">
              <Tbody>
                {props.file.name && (
                  <>
                    <Tr>
                      <Td fontWeight="bold">Name</Td>
                    </Tr>
                    <Tr>
                      <Td>{props.file.name}</Td>
                    </Tr>
                  </>
                )}
                <Tr>
                  <Td fontWeight="bold">Size</Td>
                </Tr>
                <Tr>
                  <Td>{get_size_string(props.file.size)}</Td>
                </Tr>
                {props.file.project && (
                  <>
                    <Tr>
                      <Td fontWeight="bold">Project</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Link
                          href={`/projects?id=${props.file.project.id}&mode=2`}
                          isExternal
                        >
                          {`${props.file.project.title}`}{" "}
                          <ExternalLinkIcon mx="2px" />
                        </Link>
                      </Td>
                    </Tr>
                  </>
                )}
                {props.file.site && (
                  <>
                    <Tr>
                      <Td fontWeight="bold">Site</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Link
                          href={`/sites?id=${props.file.site.id}&mode=2`}
                          isExternal
                        >
                          {`${props.file.site.name}`}{" "}
                          <ExternalLinkIcon mx="2px" />
                        </Link>
                      </Td>
                    </Tr>
                  </>
                )}
                {props.file.patient_id && (
                  <>
                    <Tr>
                      <Td fontWeight="bold">Patient ID</Td>
                    </Tr>
                    <Tr>
                      <Td>{props.file.patient_id}</Td>
                    </Tr>
                  </>
                )}
                <Tr>
                  <Td fontWeight="bold">Uploaded At</Td>
                </Tr>
                <Tr>
                  <Td>{props.file.created_at.split(".")[0]}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Uploaded By</Td>
                </Tr>
                <Tr>
                  <Td>{props.file.uploaded_by}</Td>
                </Tr>
                {props.file.notes && (
                  <>
                    <Tr>
                      <Td fontWeight="bold">Notes</Td>
                    </Tr>
                    <Tr>
                      <Td maxW="xl" sx={{ textWrap: "balance" }}>
                        {props.file.notes}
                      </Td>
                    </Tr>
                  </>
                )}
              </Tbody>
            </Table>
          </Center>
        </Box>
      </Stack>
    </Container>
  );
}

function get_size_string(size: number): string {
  if (size < 1000) {
    return `${size} Bytes`;
  }

  if (size < 1000 ** 2) {
    return `${(size / 1000).toFixed(2)} KB`;
  }

  if (size < 1000 ** 3) {
    return `${(size / 1000 ** 2).toFixed(2)} MB`;
  }

  return `${(size / 1000 ** 3).toFixed(2)} GB`;
}
