import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormikConfig, useFormik } from "formik";
import { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react";
import { BiDetail } from "react-icons/bi";
import { FiEdit2, FiSearch, FiTrash2 } from "react-icons/fi";
import { MdFindInPage, MdLocalShipping } from "react-icons/md";
import { VscError } from "react-icons/vsc";
import { useSearchParams } from "react-router-dom";
import {
  add_kit,
  bulk_send,
  delete_kit,
  edit_kit,
  get_kit,
  get_kits,
  get_pumps,
  get_sites,
  get_tubes,
  import_csv,
  mark_lost_kit,
  mark_received_kit,
  mark_returned_kit,
  send_kit,
} from "../../common/Api";
import { download_csv_sample, LoadingSpinner } from "../../common/Components";
import {
  AddKitSchema,
  BulkShipSchema,
  EditKitSchema,
  Kit,
  Pump,
  ShipSchema,
  Site,
  Tube,
  validateCSV,
} from "../../common/Models";
import { Pagination, useSearch } from "../../common/Pages";
import { MyTable } from "../users/MemberTable";
import { GeneralModal } from "../users/Modal";
import { ArrivedElement, TODAY } from "./pumps";
import Papa from "papaparse";
import { getPermissionList } from "../../token";

enum ModalModes {
  ADD,
  EDIT,
  DETAILS,
  SEND_RECEIVE,
  MARK_RECEIVED,
  BULK_SHIPMENT,
}

export function KitManagement() {
  const toast = useToast();

  const permissions = getPermissionList();
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, pageSize, mode, id, query, sort] = useSearch();

  const [totalCount, setTotalCount] = useState(1);
  const [kits, setKits] = useState(new Array<Kit>());
  const [sites, setSites] = useState(new Array<Site>());
  const [, setTubes] = useState(new Array<Tube>());

  const [kit, setKit] = useState(null as Kit | null);
  const [queryBuffer, setQueryBuffer] = useState(query as string);

  const [allKits, setAllKits] = useState(null as Kit[] | null);
  const [allPumps, setAllPumps] = useState(null as Pump[] | null);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setSearchParams((params) => {
        params.set("mode", "-1");
        params.set("id", "0");
        return params;
      });
      setKit(null);
      createForm.resetForm();
      editForm.resetForm();
    },
  });

  const createForm = useFormik({
    initialValues: {
      number: "",
      make_date: null as string | null,
      bag_conditioned: null as string | null,
      notes: "",
      site: 0,
      tubes: [{ number: "" }] as Tube[],
    },
    validationSchema: AddKitSchema,
    onSubmit: async (values) => {
      const body = values;
      if (body.make_date === "") body.make_date = null;
      if (body.bag_conditioned === "") body.bag_conditioned = null;

      toast.promise(
        add_kit(body).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
          createForm.resetForm();
        }),
        {
          success: { title: "Successfully Created" },
          error: {
            title: "Failed to Create",
            description: "Please contact support",
          },
          loading: { title: "Creating..." },
        },
      );
    },
  });

  const editForm = useFormik({
    initialValues: {
      number: "",
      make_date: null as string | null,
      bag_conditioned: null as string | null,
      notes: "",
      tubes: [{ number: "" }] as Tube[],
    },
    validationSchema: EditKitSchema,
    onSubmit: async (values) => {
      const body = values;
      if (body.make_date === "") body.make_date = null;
      if (body.bag_conditioned === "") body.bag_conditioned = null;

      toast.promise(
        edit_kit(id as number, values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Modified" },
          error: {
            title: "Failed to Modify",
            description: "Please contact support",
          },
          loading: { title: "Modifying..." },
        },
      );
    },
  });

  const shipForm = useFormik({
    initialValues: {
      frm: 0,
      to: 0,
      sent: TODAY,
      received: "" as string | null,
      tracking_number: "",
      notes: "",
    },
    validationSchema: ShipSchema,
    onSubmit: async (values) => {
      const body = values;
      if (body.received === "") body.received = null;
      toast.promise(
        send_kit(id as number, body).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Saved" },
          error: {
            title: "Failed to Save",
            description: "Please contact support",
          },
          loading: { title: "Saving..." },
        },
      );
    },
  });

  const bulkShipForm = useFormik({
    initialValues: {
      assets: [],
      frm: 0,
      to: 0,
      sent: TODAY,
      received: "" as string | null,
      tracking_number: "",
      notes: "",
    },
    validationSchema: BulkShipSchema,
    onSubmit: async (values) => {
      const body = values;
      if (body.received === "") body.received = null;
      toast.promise(
        bulk_send(body).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Saved" },
          error: {
            title: "Failed to Save",
            description: "Please contact support",
          },
          loading: { title: "Saving..." },
        },
      );
    },
  });
  const receivedForm = useFormik({
    initialValues: {
      received: TODAY,
      extra: false,
    },
    onSubmit: async (values) => {
      if (kit === null) return;
      const { extra, ...body } = values;
      const fn = !extra ? mark_received_kit : mark_returned_kit;
      toast.promise(
        fn(kit.id, body).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Saved" },
          error: {
            title: "Failed to Save",
            description: "Please contact support",
          },
          loading: { title: "Saving..." },
        },
      );
    },
  });

  useEffect(() => {
    switch (mode) {
      case ModalModes.ADD:
        break;
      case ModalModes.EDIT:
        get_kit(id as number)
          .then((res) => {
            const kit = res.data as Kit;
            setKit(kit);
            editForm.setValues({
              number: kit.number || "",
              make_date: kit.make_date || "",
              bag_conditioned: kit.bag_conditioned || "",
              notes: kit.notes || "",
              tubes: [...kit.tubes, { number: "" } as Tube] || [{ number: "" }],
            });
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          });
        break;
      case ModalModes.SEND_RECEIVE:
        get_kit(id as number)
          .then((res) => {
            const kit = res.data as Kit;
            setKit(kit);
            shipForm.setValues({
              frm: kit.locations[0].to,
              to: 0,
              sent: TODAY,
              received: null,
              tracking_number: "",
              notes: "",
            });
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          });
        break;
      case ModalModes.MARK_RECEIVED:
        get_kit(id as number)
          .then((res) => {
            const kit = res.data as Kit;
            setKit(kit);
            receivedForm.setValues({
              received: TODAY,
              extra: false,
            });
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          });
        break;
      case ModalModes.DETAILS:
        get_kit(id as number)
          .then((res) => {
            setKit(res.data as Kit);
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case ModalModes.BULK_SHIPMENT:
        break;
      default:
        return;
    }

    onOpen();
  }, [searchParams, useSearchParams]);

  useEffect(() => {
    if (mode !== -1) return;
    setIsLoading(true);
    get_kits(
      page as number,
      pageSize as number,
      query as string,
      sort as string,
    )
      .then((res) => {
        const d = res.data.kits as Array<Kit>;
        const t = res.data.total;
        setKits(d);
        setTotalCount(t);
      })
      .catch(() => {
        toast({ title: "Failed to Get", status: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, pageSize, query, mode, sort]);

  useEffect(() => {
    get_sites(1, 0, "", "").then((res) => {
      const d = res.data.sites as Array<Site>;
      setSites(d);
    });
  }, []);

  useEffect(() => {
    get_tubes(1, 0, "", "").then((res) => {
      const d = (res.data.tubes as Array<Tube>).filter((v) => v.kind === 1);
      setTubes(d);
    });
  }, []);

  useEffect(() => {
    get_kits(1, 0, "", "").then((res) => {
      const d = res.data.kits as Array<Kit>;
      setAllKits(d);
    });
  }, []);

  useEffect(() => {
    get_pumps(1, 0, "", "").then((res) => {
      const d = res.data.pumps as Array<Pump>;
      setAllPumps(d);
    });
  }, []);

  if (isLoading) {
    return (
      <Container
        textAlign={"center"}
        alignSelf={"center"}
        py={{ base: "4", md: "8" }}
        px={{ base: "0", md: 8 }}
      >
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 8 }}>
      <Box borderRadius={{ base: "none", md: "lg" }}>
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack direction={{ base: "column", md: "row" }} justify="end">
              {(permissions[4].verb & 1) != 0 && (
                <InputGroup maxW="xs">
                  <InputLeftElement pointerEvents="none">
                    <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search"
                    onKeyUp={(e) => {
                      if (e.key !== "Enter") return;

                      setSearchParams((params) => {
                        params.set("query", queryBuffer);
                        params.set("page", "1");
                        return params;
                      });
                    }}
                    autoFocus={true}
                    onChange={(e) => {
                      setQueryBuffer(e.target.value);
                    }}
                    value={queryBuffer}
                  />
                </InputGroup>
              )}
              {(permissions[4].verb & 2) != 0 && (
                <>
                  <FormControl
                    colorScheme="blue"
                    justifyContent="end"
                    display="flex"
                  >
                    <button
                      data-tooltip-target="tooltip-default"
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-l-lg text-sm font-bold px-2.5 py-2.5 text-center"
                      title="Download a Sample CSV"
                      onClick={() => {
                        download_csv_sample("kits");
                      }}
                    >
                      i
                    </button>
                    <FormLabel
                      htmlFor="file_button"
                      textColor="white"
                      backgroundColor="#5728b5"
                      cursor="pointer"
                      py={2.5}
                      px={4}
                      rounded={5}
                      roundedLeft={0}
                      m={0}
                      w="max-content"
                      fontWeight="bold"
                      _hover={{
                        backgroundColor: "#6530cf",
                      }}
                    >
                      Import CSV
                    </FormLabel>
                    <Input
                      id="file_button"
                      name="file"
                      type="file"
                      display="none"
                      accept=".csv"
                      onChange={(e) => {
                        const files = e.currentTarget.files;
                        if (!files) return;
                        const file = files[0];
                        const reader = new FileReader();
                        reader.onload = ({ target }) => {
                          if (!target) return;
                          const columns = [
                            "site",
                            "number",
                            "make_date",
                            "bag_conditioned",
                            "notes",
                            "tubes",
                          ];
                          const csv = Papa.parse<any>(target.result as string, {
                            header: true,
                          });
                          const errors = validateCSV(csv, columns);
                          if (errors.length !== 0) {
                            toast({
                              title: "Errors loading csv file",
                              description: errors.join(" | "),
                              status: "error",
                            });
                            return;
                          }

                          const tid = toast({
                            title: "Importing ...",
                            status: "loading",
                            duration: null,
                          });
                          import_csv({
                            resource: "kits",
                            data: target.result as string,
                          })
                            .then(() => {
                              toast.update(tid, {
                                status: "success",
                                title: "Finished importing successfully",
                                isClosable: true,
                                duration: null,
                                onCloseComplete: () => window.location.reload(),
                              });
                            })
                            .catch((err) => {
                              toast.update(tid, {
                                status: "error",
                                title: "Failed to import",
                                description: err.response.data.join(" | "),
                                isClosable: true,
                                duration: null,
                                onCloseComplete: () => window.location.reload(),
                              });
                            });
                        };

                        reader.readAsText(file);
                      }}
                    />
                  </FormControl>
                  <Button
                    onClick={() => {
                      setSearchParams((params) => {
                        params.set("id", "-1");
                        params.set("mode", ModalModes.BULK_SHIPMENT.toString());
                        return params;
                      });
                    }}
                    id="bulk_shipment"
                  >
                    Bulk Shipment
                  </Button>
                  <Button
                    onClick={() => {
                      setSearchParams((params) => {
                        params.set("id", "-1");
                        params.set("mode", ModalModes.ADD.toString());
                        return params;
                      });
                    }}
                    id="add"
                  >
                    Add Kit
                  </Button>
                </>
              )}
            </Stack>
          </Box>
          {(permissions[4].verb & 1) != 0 && (
            <>
              <Box overflowX="auto">
                <MyTable<Kit>
                  sortColumn={sort as string}
                  setSearchParams={setSearchParams}
                  rowAction={(p: Kit) => {
                    setSearchParams((params) => {
                      params.set("id", p.id.toString());
                      params.set("mode", ModalModes.DETAILS.toString());
                      return params;
                    });
                  }}
                  columns={[
                    { name: "Kit Serial No", width: "25%", sort_id: "number" },
                    { name: "Current Location", width: "35%" },
                    { name: "Tubes Within", width: "15%" },
                    { name: "Actions", width: "25%" },
                  ]}
                  data={kits}
                  bindings={[
                    (r: Kit) => r.number,
                    (r: Kit) => {
                      if (r.lost) return <Badge colorScheme="red">Lost</Badge>;
                      return (
                        <>
                          {!r.locations[0].date_received && (
                            <Badge colorScheme="green" mr={2}>
                              In-Transit {"->"}
                            </Badge>
                          )}
                          <Link
                            href={`/sites?id=${r.locations[0].id}&mode=2`}
                            isExternal
                            onClick={(e) => e.stopPropagation()}
                          >
                            {`${r.locations[0].name}`}{" "}
                            <ExternalLinkIcon mx="2px" />
                          </Link>
                        </>
                      );
                    },
                    (r) => `${new Set(r.tubes.map((v) => v.id)).size}`,
                  ]}
                  rk={(r: Kit) => `${r.id}`}
                  functions={[
                    {
                      title: "Edit",
                      icon: <FiEdit2 />,
                      condition: () => (permissions[4].verb & 2) != 0,
                      fn: (p: Kit) => {
                        setSearchParams((params) => {
                          params.set("id", p.id.toString());
                          params.set("mode", ModalModes.EDIT.toString());
                          return params;
                        });
                      },
                    },
                    {
                      title: "Mark as Lost",
                      icon: <VscError />,
                      condition: (r) =>
                        !r.lost && (permissions[4].verb & 2) != 0,
                      fn: (p) => {
                        if (
                          !window.confirm(
                            "Are you sure you want to mark this kit as lost?",
                          )
                        )
                          return;

                        mark_lost_kit(p.id).then(() =>
                          window.location.reload(),
                        );
                      },
                    },
                    {
                      title: "Mark as Found",
                      icon: <MdFindInPage />,
                      condition: (r) =>
                        r.lost && (permissions[4].verb & 2) != 0,
                      fn: (p) => {
                        if (
                          !window.confirm(
                            "Are you sure you want to mark this kit as found?",
                          )
                        )
                          return;

                        mark_lost_kit(p.id).then(() =>
                          window.location.reload(),
                        );
                      },
                    },
                    {
                      title: "Send/Receive",
                      icon: <MdLocalShipping />,
                      condition: (r) =>
                        !r.lost && (permissions[4].verb & 2) != 0,
                      fn: (p: Kit) => {
                        setSearchParams((params) => {
                          params.set("id", p.id.toString());
                          if (p.locations[0].date_received === null) {
                            params.set(
                              "mode",
                              ModalModes.MARK_RECEIVED.toString(),
                            );
                          } else {
                            params.set(
                              "mode",
                              ModalModes.SEND_RECEIVE.toString(),
                            );
                          }
                          return params;
                        });
                      },
                    },
                    {
                      title: "Show Details",
                      icon: <BiDetail />,
                      fn: (p: Kit) => {
                        setSearchParams((params) => {
                          params.set("id", p.id.toString());
                          params.set("mode", ModalModes.DETAILS.toString());
                          return params;
                        });
                      },
                    },
                    {
                      title: "Delete",
                      icon: <FiTrash2 color="darkred" />,
                      condition: (r) =>
                        !r.lost && (permissions[4].verb & 4) != 0,
                      fn: (p) => {
                        const yes = window.confirm(
                          `Are you sure you want to delete the kit "${p.number}"? This action cannot be undone`,
                        );
                        if (!yes) return;

                        toast.promise(
                          delete_kit(p.id).then(() => {
                            setSearchParams((params) => {
                              params.set("mode", "-1");
                              return params;
                            });
                            window.location.reload();
                          }),
                          {
                            success: { title: "Successfully Deleted" },
                            error: {
                              title: "Failed to Delete Kit",
                              description: "Please contact support",
                            },
                            loading: { title: "Deleting..." },
                          },
                        );
                      },
                    },
                  ]}
                />
              </Box>
              <Box bg="bg.surface">
                <Container>
                  <Pagination
                    count={totalCount}
                    pageSize={pageSize as number}
                    siblingCount={2}
                    page={page as number}
                    onChange={(e: any) => {
                      setSearchParams((params) => {
                        params.set("page", e.page);
                        return params;
                      });
                    }}
                  />
                </Container>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 my-3 mx-auto"
                  onChange={(e) => {
                    setSearchParams((params) => {
                      params.set("pageSize", e.target.value);
                      return params;
                    });
                  }}
                  value={pageSize}
                >
                  <option value="10">10 Results per Page</option>
                  <option value="20">20 Results per Page</option>
                  <option value="30">30 Results per Page</option>
                  <option value="50">50 Results per Page</option>
                  <option value="100">100 Results per Page</option>
                </select>
              </Box>
            </>
          )}
        </Stack>
      </Box>
      {(permissions[4].verb & 2) != 0 && mode === ModalModes.ADD && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={createForm.handleSubmit}
          body={
            <AddKitElement formik={createForm} disabled={[]} sites={sites} />
          }
          title={"Add Kit"}
          buttonText="Save"
        />
      )}
      {(permissions[4].verb & 2) != 0 && mode === ModalModes.EDIT && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={editForm.handleSubmit}
          body={<AddKitElement formik={editForm} disabled={[]} edit={true} />}
          title={"Edit Kit"}
          buttonText="Save"
        />
      )}
      {(permissions[4].verb & 2) != 0 &&
        mode === ModalModes.SEND_RECEIVE &&
        sites.length > 0 && (
          <GeneralModal
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            handleSubmit={shipForm.handleSubmit}
            body={<ShipKitElement formik={shipForm} kit={kit} sites={sites} />}
            title={"Send/Receive Kit"}
            buttonText="Save"
          />
        )}
      {(permissions[4].verb & 2) != 0 && mode === ModalModes.MARK_RECEIVED && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={receivedForm.handleSubmit}
          isSubmitting={receivedForm.isSubmitting}
          body={<ArrivedElement formik={receivedForm} asset={kit} />}
          title={"Mark Kit as Received"}
          buttonText="Save as Received"
          extraButtonText="Save as Returned"
          setFieldValue={receivedForm.setFieldValue}
        />
      )}
      {(permissions[4].verb & 1) != 0 &&
        mode === ModalModes.DETAILS &&
        kit &&
        kit.id && (
          <GeneralModal
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            body={<DetailsElement kit={kit} />}
            title={"Kit Details"}
          />
        )}
      {(permissions[4].verb & 2) != 0 &&
        mode === ModalModes.BULK_SHIPMENT &&
        sites.length > 0 && (
          <GeneralModal
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            handleSubmit={bulkShipForm.handleSubmit}
            body={
              <BulkShipmentElement
                formik={bulkShipForm}
                kits={allKits}
                pumps={allPumps}
                sites={sites}
              />
            }
            title={"Bulk Shipment"}
            buttonText="Save"
          />
        )}
    </Container>
  );
}

export function BulkShipmentElement(props: {
  formik: any;
  sites: Site[];
  kits: Kit[] | null;
  pumps: Pump[] | null;
}) {
  const formik = props.formik;
  const sites: Array<Site> = props.sites || [];
  const kits: Array<Kit> = props.kits || [];
  const pumps: Array<Pump> = props.pumps || [];
  const siteAssets = kits
    .sort((a, b) => a.number.localeCompare(b.number))
    .map((v) => {
      return {
        id: v.id,
        name: v.number,
        kind: "k",
        location: v.locations.sort(
          (a, b) => -a.date_sent.localeCompare(b.date_sent),
        )[0],
      };
    })
    .concat(
      pumps
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((v) => {
          return {
            id: v.id,
            name: v.name,
            kind: "p",
            location: v.locations.sort(
              (a, b) => -a.date_sent.localeCompare(b.date_sent),
            )[0],
          };
        }),
    )
    .filter(
      (v) => v.location.id === formik.values.frm && v.location.date_received,
    );

  const dates: Array<string> = formik.values.assets.map((x: string) => {
    const lst: Array<Pump | Kit> = x.startsWith("k") ? kits : pumps;
    return lst
      .filter((v: Kit | Pump) => v.id == parseInt(x.substring(2)))[0]
      .locations.sort((a, b) => -a.date_sent.localeCompare(b.date_sent))[0].date_received;
  });

  const minDate = dates.sort((a, b) => -a.localeCompare(b))[0] || "";

  console.log(formik.values.assets);

  if (sites === null) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box width="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="frm"
                isInvalid={formik.errors.frm && formik.touched.frm}
              >
                <FormLabel textAlign="center">From</FormLabel>
                <Select
                  placeholder="Select option"
                  id="frm"
                  name="frm"
                  onChange={(e) =>
                    formik.setFieldValue("frm", parseInt(e.target.value))
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.frm}
                >
                  {sites.map((s) => (
                    <option value={s.id} key={s.id}>
                      {s.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formik.errors.frm}</FormErrorMessage>
              </FormControl>
              {sites.length === 0 || (
                <FormControl
                  id="to"
                  isInvalid={formik.errors.to && formik.touched.to}
                >
                  <FormLabel textAlign="center">To</FormLabel>
                  <Select
                    placeholder="Select option"
                    id="to"
                    name="to"
                    onChange={(e) =>
                      formik.setFieldValue("to", parseInt(e.target.value))
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.to}
                  >
                    {sites
                      .filter((s) => s.id !== formik.values.frm)
                      .map((s) => (
                        <option value={s.id} key={s.id}>
                          {s.name}
                        </option>
                      ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.to}</FormErrorMessage>
                </FormControl>
              )}
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="assets"
                isInvalid={formik.errors.assets && formik.touched.assets}
              >
                <FormLabel textAlign="center">
                  Assets ({formik.values.assets.length} Selected)
                </FormLabel>
                <select
                  multiple
                  id="countries_multiple"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  disabled={!formik.values.frm || siteAssets.length === 0}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "assets",
                      Array.prototype.slice
                        .call(e.target.selectedOptions)
                        .map((v) => v.value),
                    );
                  }}
                  onBlur={formik.handleBlur}
                >
                  {!formik.values.frm ? (
                    <option>Please select a site to ship from</option>
                  ) : siteAssets.length ? (
                    siteAssets.map((v, idx) => (
                      <option key={idx} value={`${v.kind}-${v.id}`}>
                        {v.name}
                      </option>
                    ))
                  ) : (
                    <option>No assets to ship from this site</option>
                  )}
                </select>
                <FormErrorMessage>{formik.errors.assets}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="sent"
                isInvalid={formik.errors.sent && formik.touched.sent}
              >
                <FormLabel textAlign="center">Sent On</FormLabel>
                <Input
                  name="sent"
                  id="sent"
                  size="md"
                  type="date"
                  min={
                    minDate
                  }
                  max={TODAY}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sent}
                />
                <FormErrorMessage>{formik.errors.sent}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="received"
                isInvalid={formik.errors.received && formik.touched.received}
              >
                <FormLabel textAlign="center">Received On</FormLabel>
                <Input
                  name="received"
                  id="received"
                  size="md"
                  type="date"
                  min={formik.values.sent || TODAY}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.received}
                />
                <FormErrorMessage>{formik.errors.received}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="tracking_number"
                isInvalid={
                  formik.errors.tracking_number &&
                  formik.touched.tracking_number
                }
              >
                <FormLabel>Tracking Number</FormLabel>
                <Input
                  id="tracking_number"
                  name="tracking_number"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tracking_number}
                />
                <FormErrorMessage>
                  {formik.errors.tracking_number}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function AddKitElement(props: {
  formik: any;
  disabled: string[];
  sites?: Site[];
  edit?: boolean;
}): ReactElement {
  const formik = props.formik;
  const disabled: string[] = props.disabled;
  const sites: Array<Site> = props.sites || [];
  const tubes: Array<Tube> = formik.values.tubes || [{}];

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box width="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="number"
                isInvalid={formik.errors.number && formik.touched.number}
                isDisabled={disabled.filter((v) => v === "number").length !== 0}
              >
                <FormLabel>Serial Number</FormLabel>
                <Input
                  id="number"
                  name="number"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.number}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      document.getElementById(`tube0`)?.focus();
                    }
                  }}
                />
                <FormErrorMessage>{formik.errors.number}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <Stack spacing="1" direction="column">
                <FormLabel>Tubes</FormLabel>
                {tubes.slice().map((v, i) => (
                  <div className="w-full px-3 mb-6 md:mb-0" key={`tube${i}`}>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id={`tube${i}`}
                      name={`tube${i}`}
                      type="text"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (i !== tubes.length - 1) {
                            document.getElementById(`tube${i + 1}`)?.focus();
                          }
                        }
                      }}
                      onChange={(e) => {
                        const newTubes = [
                          ...tubes.slice(0, i),
                          { number: e.target.value },
                          ...tubes.slice(i + 1),
                        ];
                        if (
                          i === tubes.length - 1 &&
                          e.target.value &&
                          e.target.value !== ""
                        ) {
                          formik.setFieldValue("tubes", [
                            ...newTubes,
                            { number: "" },
                          ]);
                        } else {
                          formik.setFieldValue("tubes", newTubes);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={v.number}
                    ></input>
                  </div>
                ))}
              </Stack>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl id="make_date">
                <FormLabel textAlign="center">Make Date</FormLabel>
                <Input
                  name="make_date"
                  id="make_date"
                  size="md"
                  type="date"
                  max={TODAY}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.make_date}
                />
                <FormErrorMessage>{formik.errors.make_date}</FormErrorMessage>
              </FormControl>
              <FormControl id="bag_conditioned">
                <FormLabel textAlign="center">Bag Conditioned On</FormLabel>
                <Input
                  name="bag_conditioned"
                  id="bag_conditioned"
                  size="md"
                  type="date"
                  max={TODAY}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bag_conditioned}
                />
                <FormErrorMessage>
                  {formik.errors.bag_conditioned}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            {props.edit || (
              <Stack>
                <FormControl
                  id="site"
                  isInvalid={formik.errors.site && formik.touched.site}
                  isDisabled={disabled.filter((v) => v === "name").length !== 0}
                >
                  <FormLabel>Current Location</FormLabel>
                  <Select
                    placeholder="Select option"
                    id="site"
                    name="site"
                    onChange={(e) => {
                      formik.setFieldValue("site", parseInt(e.target.value));
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.site}
                  >
                    {sites.map((s) => (
                      <option value={s.id} key={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.site}</FormErrorMessage>
                </FormControl>
              </Stack>
            )}
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
                isDisabled={disabled.filter((v) => v === "notes").length !== 0}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function ShipKitElement(props: any): ReactElement {
  const formik = props.formik;
  const sites: Array<Site> = props.sites || [];
  const kit: Kit = props.kit;

  if (kit === null) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box width="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl>
                <FormLabel textAlign="center">From</FormLabel>
                <Input
                  isDisabled={true}
                  value={
                    formik.values.frm &&
                    sites.filter((s) => s.id === formik.values.frm)[0].name
                  }
                />
              </FormControl>
              {sites.length === 0 || (
                <FormControl
                  id="to"
                  isInvalid={formik.errors.to && formik.touched.to}
                >
                  <FormLabel textAlign="center">To</FormLabel>
                  <Select
                    placeholder="Select option"
                    id="to"
                    name="to"
                    onChange={(e) =>
                      formik.setFieldValue("to", parseInt(e.target.value))
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.to}
                  >
                    {sites
                      .filter((s) => s.id !== formik.values.frm)
                      .map((s) => (
                        <option value={s.id} key={s.id}>
                          {s.name}
                        </option>
                      ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.to}</FormErrorMessage>
                </FormControl>
              )}
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl id="sent">
                <FormLabel textAlign="center">Sent On</FormLabel>
                <Input
                  name="sent"
                  id="sent"
                  size="md"
                  type="date"
                  min={kit.locations[0].date_received?.toString() || TODAY}
                  max={TODAY}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sent}
                />
                <FormErrorMessage>{formik.errors.sent}</FormErrorMessage>
              </FormControl>
              <FormControl id="received">
                <FormLabel textAlign="center">Received On</FormLabel>
                <Input
                  name="received"
                  id="received"
                  size="md"
                  type="date"
                  min={kit.locations[0].date_received?.toString() || TODAY}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.received}
                />
                <FormErrorMessage>{formik.errors.received}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="tracking_number"
                isInvalid={
                  formik.errors.tracking_number &&
                  formik.touched.tracking_number
                }
              >
                <FormLabel>Tracking Number</FormLabel>
                <Input
                  id="tracking_number"
                  name="tracking_number"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tracking_number}
                />
                <FormErrorMessage>
                  {formik.errors.tracking_number}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function DetailsElement(props: { kit: Kit }): ReactElement {
  return (
    <Tabs variant="soft-rounded" colorScheme="blue">
      <TabList>
        <Tab>Overview</Tab>
        <Tab>Tubes</Tab>
        <Tab>Location History</Tab>
        <Tab>Tube History</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Center>
            <Table size="sm" variant="striped">
              <Tbody>
                {props.kit.number && (
                  <>
                    <Tr>
                      <Td fontWeight="bold">Serial Number</Td>
                    </Tr>
                    <Tr>
                      <Td>{props.kit.number}</Td>
                    </Tr>
                  </>
                )}
                <Tr>
                  <Td fontWeight="bold">Lost</Td>
                </Tr>
                <Tr>
                  <Td>{props.kit.lost ? "Yes" : "No"}</Td>
                </Tr>
                {props.kit.notes && (
                  <>
                    <Tr>
                      <Td fontWeight="bold">Notes</Td>
                    </Tr>
                    <Tr>
                      <Td maxW="xl" sx={{ textWrap: "balance" }}>
                        {props.kit.notes}
                      </Td>
                    </Tr>
                  </>
                )}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Number</Th>
                  <Th fontWeight="bold">Part Number</Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.kit.tubes.map((c) => (
                  <Tr key={c.id}>
                    <Td>
                      <Link href={`/tubes?id=${c.id}&mode=2`} isExternal>
                        {`${c.number}`} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Td>
                    <Td> {`${c.part_number}`}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Sent On</Th>
                  <Th fontWeight="bold">Received On</Th>
                  <Th fontWeight="bold">To</Th>
                  <Th fontWeight="bold">Tracking Number</Th>
                  <Th fontWeight="bold">Notes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.kit.locations.map((p) => (
                  <Tr key={p.id}>
                    <Td>{`${p.date_sent}`} </Td>
                    <Td>{`${p.date_received || "In-Transit"}`} </Td>
                    <Td>
                      <Link href={`/sites?id=${p.to}&mode=2`} isExternal>
                        {`${p.name}`} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Td>
                    <Td> {p.tracking_number || "None"} </Td>
                    <Td maxW="xl" sx={{ textWrap: "balance" }}>
                      {" "}
                      {`${p.notes}`}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Number</Th>
                  <Th fontWeight="bold">Part Number</Th>
                  <Th fontWeight="bold">Status</Th>
                  <Th fontWeight="bold">On</Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.kit.tube_history.map((c) => (
                  <Tr key={c.id}>
                    <Td>
                      <Link href={`/tubes?id=${c.id}&mode=2`} isExternal>
                        {`${c.number}`} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Td>
                    <Td> {`${c.part_number}`}</Td>
                    <Td> {`${c.status}`}</Td>
                    <Td> {`${c.created_at}`}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
